"use client";

import { getCustomerTagId } from "@mpay/web-tickets/src/components/sections/public/basket/meta/getCustomerTagId.client";
import { getSessionId } from "@mpay/web-tickets/src/components/sections/public/basket/meta/getSessionId.client";
import { canUseDOM } from "exenv";
import { usePathname } from "next/navigation";
import * as React from "react";

export function PageViewAnalytics() {
  getCustomerTagId();
  getSessionId();
  const path = usePathname();
  React.useEffect(() => {
    if (canUseDOM) {
      const referrer = document.referrer;
      console.log("PageView", { path, referrer });
      fetch("/api/pageview", {
        method: "POST",
        body: JSON.stringify({ path, referrer }),
      }).catch((e) => {});
    }
  }, [path]);
  return null;
}
